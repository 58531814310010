import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Banner from '../components/Banner'

import Logo_Xplore_Compressors from '../assets/images/Logo_Xplore_Compressors_negativ.png'
import Motivation from '../assets/images/motivation.jpg'
import Values from '../assets/images/values.jpeg'
import Vision from '../assets/images/vision.jpg'

import Background from '../assets/images/background.jpg'
import AlarmsIcon from '../assets/images/alarms_ICON.png'
import MonitoringIcon from '../assets/images/monitoring_ICON.png'
import TrendingIcon from '../assets/images/trending_ICON.png'
import CostIcon from '../assets/images/cost_ICON.png'
import ImplementationStrategy from '../assets/images/ImplementationStrategy.png'

import Logo_Xplore_Powder from '../assets/images/Logo_Xplore_Coating_negativ.png'
import ServiceIcon from '../assets/images/service_ICON.png'
import ProcessIcon from '../assets/images/process_ICON.png'
import QualityIcon from '../assets/images/quality_ICON.png'
import ProductivityIcon from '../assets/images/productivity_ICON.png'

import Logo_Xplore_Main from '../assets/images/Logo_Xplore_Main_negativ.png'

import DashboardCompressors from '../assets/images/dashboard_compressors.png'
import DashboardPowder from '../assets/images/dashboard_powder.png'
import DashboardTestbench from '../assets/images/dashboard_testbench.png'
import DashboardProduction from '../assets/images/dashboard_production.png'

class HomeIndex extends React.Component {
    render() {

        return (
            <Layout>
                <Helmet
                    title="NEA Xplore"
                    meta={[
                        { name: 'description', content: 'Digital Platform Solutions' },
                        { name: 'keywords', content: 'IIoT, Compressor, Powder, Industry 4.0, Industrie 4.0' },
                    ]}
                >
                </Helmet>

                <Banner />

                <div id="main">
                    <section id="Overview" className="tiles">
                        <article style={{backgroundImage: `url(${DashboardPowder})`}}>
                            <header className="major">
                                <left><img src={Logo_Xplore_Powder} alt="" style={{width:200,}} /></left>
                                <p>Digitalisation & Analytics for Powder Coatings</p>                  
                            </header>
                            <Link to="/#XplorePowder" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${DashboardCompressors})`}}>
                            <header className="major">
                                <left><img src={Logo_Xplore_Compressors} alt="" style={{width:200,}} /></left>
                                <p>Condition Management in H&sup2;, Biogas and Reciprocating Compressors</p>
                            </header>
                            <Link to="/#XploreCompressors" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${DashboardTestbench})`}}>
                            <header className="major">
                                <left><img src={Logo_Xplore_Main} alt="" style={{width:200,}} /></left>
                                <p>Immersive digital testbench experience</p>
                                <Link to="/#XploreTestbench"></Link>
                            </header>
                            <Link to="/#XploreTestbench" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${DashboardProduction})`}}>
                            <header className="major">
                                <left><img src={Logo_Xplore_Main} alt="" style={{width:200,}} /></left>
                                <p>Production Excellence for Manufacturing Processes</p>
                            </header>
                        </article>
                    </section>
 
                    <section id="ConditionManagement">
                        <div className="inner">
                                
                                <div className="grid-wrapper">
                                    <div className="col-5">
                                        <header className="major">
                                            <h2>Condition Management</h2>
                                        </header>
                                        <p>To amalgamate Service Excellence with Digital Expertise, NEA X together with NEAC offer NEAC CONDITION MANAGEMENT – a real 360°-solution from Digital Service Strategy to Maintenance Execution with customized service levels .</p>
                                        <ul className="actions">
                                            <li><a href="https://www.neuman-esser.de/en/services/" className="button next">Get Started</a></li>
                                        </ul>                                       
                                    </div>
                                    <div className="col-1">
                                           
                                    </div>
                                    <div className="col-6">
                                        <img src={ImplementationStrategy} alt="" height="300" style={{filter:"invert(1)"}} />   
                                    </div>
                                </div>
                                
                            </div>
                    </section>  

                    <section id="XploreCompressors" className="spotlights">
                            
                        <section>
                            <span className="image">
                                <img src={DashboardCompressors} alt="" />
                            </span>
                            <div className="content">
                                <div className="inner">
                                    
                                    <header className="major">
                                    <img src={Logo_Xplore_Compressors} alt="" style={{width:200}} />
                                    </header>
                                    <p>XPLORE is your platform solution of choice if you like to support your engineers in monitoring, analyzing and diagnosing machine performance of critical assets. <br></br> Built on the extensive knowledge of NEAC engineers, it allows to assess the health of compressor systems at any time from anywhere.</p>
                                </div>
                            </div>
                        </section>
                        

                    </section>
                    
                    <section className="bglight">
                    <div className="inner">
                        
                        <div className="grid-wrapper" >
                            <div className="col-3">
                                <center>
                                <img src={CostIcon} alt="" height="50" style={{filter:"invert(1)"}} />
                                </center>
                                <p>Cost-effective data processing and analytics combined with optimized hardware results in an affordable solution with immediate impact and quick RoI.</p>
                            </div>
                            <div className="col-3">
                                <center>
                                    <img src={TrendingIcon} alt="" height="50" style={{filter:"invert(1)"}} />
                                </center>
                                <p>Quick access to real-time data or to historical information, machine reports, KPIs gives insights almost on the fly.</p>
                            </div>
                            <div className="col-3">
                            <center>
                                <img src={MonitoringIcon} alt="" height="50" style={{filter:"invert(1)"}} />
                                </center>
                                <p>Based on slow data, visualize and analyze parameter trends and critical developments for risk assessment and predictive maintenance.</p>
                            </div>
                            <div className="col-3">
                                <center>
                                    <img src={AlarmsIcon} alt="" height="50" style={{filter:"invert(1)"}}/>
                                </center>
                                <p>Powerful Alarm Management supports the handling of hard and soft alarms remotely. Set in-application alarms for detailed supervision of parameters.</p>
                            </div>
                        </div>
                        </div>
                    </section>

                    <section>
                        
                    </section>
                    <section id="XplorePowder" className="spotlights">
                        <section></section>
                    <section>
                            <span className="image">
                                <img src={DashboardPowder} alt="" />
                            </span>
                            <div className="content">
                                <div className="inner">
                                    
                                    <header className="major">
                                    <img src={Logo_Xplore_Powder} alt="" style={{width:200}} />
                                    </header>
                                    <p>Track your products along the entire production process – from raw material to packaging, seamlessly, for any batch, with a single solution.</p>
                                </div>
                            </div>
                        </section>
                        
                    </section>
                    
                    <section className="bglighter">
                        <div className="inner">
                        <div className="grid-wrapper">
                            <div className="col-3">
                                <center>
                                <img src={ProductivityIcon} alt="" height="50" style={{filter:"invert(1)"}} />
                                </center>
                                <p>Analyze availability and throughput and take according actions</p>
                            </div>
                            <div className="col-3">
                                <center>
                                    <img src={ProcessIcon} alt="" height="50" style={{filter:"invert(1)"}} />
                                </center>
                                <p>Trace your products and batches, identify cycle times for improved production scheduling</p>
                            </div>
                            <div className="col-3">
                            <center>
                                <img src={QualityIcon} alt="" height="50" style={{filter:"invert(1)"}} />
                                </center>
                                <p>Automated machine parameter setting allows identical production conditions for each batch of every product</p>
                            </div>
                            <div className="col-3">
                                <center>
                                    <img src={ServiceIcon} alt="" height="50" style={{filter:"invert(1)"}}/>
                                </center>
                                <p>Machine performance analysis allows for remote monitoring of potential sources of failure</p>
                            </div>
                        </div>
                        </div>
                        </section>


                        <section id="XploreTestbench" className="spotlights">
                            
                            <section>
                                <span className="image">
                                    <img src={DashboardTestbench} alt="" />
                                </span>
                                <div className="content">
                                    <div className="inner">
                                        
                                        <header className="major">
                                        <img src={Logo_Xplore_Main} alt="" style={{width:200}} />
                                        </header>
                                        <p>Not all your challenges can be tackled with a standardized system. Together with you we are creating ways to make you even better.
                                        </p>

                                    </div>
                                </div>
                            </section>
                            
    
                        </section>
                        <section className="bglight">
                        <div className="inner">
                                <header className="major">
                                        <h2>Customized solutions</h2>
                                </header>
                                <p>
                                Is it about projects to support your employees at the core of your value chain in their daily work, or about the automation of sub-processes? You would like to be able to be faster in decision-making and would like to base it on proven data?
                                <br></br>
                                We find solutions for demanding challenges on your shopfloor covering all levels of IT. In individual development projects visualization-, analysis- and control software with interfaces to upstream or downstream systems is fitted to your individual landscape.
                                </p>
                            </div>
                        </section>
                        <section id="one" className="tiles">
                        <article style={{backgroundImage: `url(${Motivation})`}}>
                            <header className="major">
                                <h3 style={{textTransform: "uppercase"}}>Our Motivation</h3>
                                <p>What drives us is our passion for data, machines and insights supporting decisions and actions that have a measurable impact for our clients‘ operations.</p>
                            </header>
                        </article>
                        <article style={{backgroundImage: `url(${Values})`}}>
                            <header className="major">
                                <h3 style={{textTransform: "uppercase"}}>Our Values</h3>
                                <p>In everything we do we are truly dedicated to our clients‘ needs and their targets.</p>
                                <p>We are strongly aware of our heritage. Thus, we always combine the knowledge of a plant engineering company with our digital expertise creating solutions that are providing spot-on results through intuitive and hassle-free handling.</p>
                            </header>
                        </article>
                        <article style={{backgroundImage: `url(${Vision})`}}>
                            <header className="major">
                                <h3 style={{textTransform: "uppercase"}}>Our Vision</h3>
                                <p>We want to establish our platform solution as a crucial element in our clients‘ operations. When it comes to insights from machine data, we want to be the undoubted partner for our clients striving for digital excellence at the core of their value chain.</p>
                            </header>
                        </article>
                        <article style={{backgroundImage: `url(${Background})`}}>
                            <header className="major">
                                <h3 style={{textTransform: "uppercase"}}>Our Background</h3>
                                <p>Agile. Solution. Experts. We are taking NEUMAN & ESSER GROUP’s claim very seriously and bring in the knowledge from a broad range of backgrounds with proven experience.</p>
                            </header>
                        </article>
                    </section>
                </div>
            </Layout>
        )
    }
}

export default HomeIndex
